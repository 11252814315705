var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "range-wrap", on: { click: _vm.onClick } }, [
      _c("div", { ref: "range", staticClass: "form-range", class: _vm.style }, [
        _c("span", { staticClass: "range-road" }, [
          _c("span", {
            staticClass: "range-fill",
            style: { width: _vm.position + "%" }
          })
        ]),
        _vm._v(" "),
        _c("span", {
          staticClass: "range-handle",
          style: { left: _vm.position + "%" },
          on: { touchstart: _vm.grabStart, mousedown: _vm.grabStart }
        })
      ])
    ]),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }